@font-face {
  font-family: inter;
  src:
    url('fonts/Inter-Medium.woff') format('woff'),
    url('fonts/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: inter;
  src:
    url('fonts/Inter-SemiBold.woff') format('woff'),
    url('fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: inter;
  src:
    url('fonts/Inter-Bold.woff') format('woff'),
    url('fonts/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: instrument;
  src: url('fonts/InstrumentSerif-Regular.ttf');
}

@font-face {
  font-family: instrument;
  src: url('fonts/InstrumentSerif-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: inter;
  src: url('fonts/Inter-Italic-var.ttf');
  src: url('fonts/Inter-Italic-var.woff2') format('woff2');
  font-style: italic;
}

:root {
  /* Violet */
  --color-violet-bright: #a157ff;
  --color-violet-strong: #810ff8;
  --color-violet-dark: #6400cc;
  --color-violet-light: #ead6ff;
  --color-violet-alpha-30: rgb(100 0 204 / 30%);
  --color-violet-alpha-10: rgb(100 0 204 / 10%);

  /* This is the same color as alpha-10 without the alpha value. */
  --color-violet-extra-light: #f1e8fb;

  /* Blue Violet */
  --color-blueViolet-bright: #7d5dff;
  --color-blueViolet-strong: #5d35ff;
  --color-blueViolet-dark: #491ef3;
  --color-blueViolet-light: #f4f3fc;
  --color-blueViolet-alpha-30: rgb(73 30 243 / 30%);
  --color-blueViolet-alpha-10: rgb(73 30 243 / 10%);

  /* This is the same color as alpha-10 without the alpha value. */
  --color-blueViolet-extra-light: #ebeffc;

  /* Blue */
  --color-blue-bright: #7d5dff;
  --color-blue-strong: #1e66f2;
  --color-blue-dark: #004de2;
  --color-blue-light: #dce8ff;
  --color-blue-alpha-30: rgb(0 77 226 / 30%);
  --color-blue-alpha-10: rgb(0 77 226 / 10%);

  /* This is the same color as alpha-10 without the alpha value. */
  --color-blue-extra-light: #eaeefc;

  /* Baby blue */
  --color-babyBlue-bright: #72ccff;
  --color-babyBlue-strong: #26a3e9;
  --color-babyBlue-dark: #0081c9;
  --color-babyBlue-light: #cfeeff;
  --color-babyBlue-night: #0b3146;
  --color-babyBlue-alpha-30: rgb(38 162 233 / 30%);
  --color-babyBlue-alpha-10: rgb(38 162 233 / 10%);

  /* Turquoise */
  --color-turquoise-bright: #44d2db;
  --color-turquoise-strong: #00afbb;
  --color-turquoise-dark: #008d96;
  --color-turquoise-light: #d1f0ee;
  --color-turquoise-alpha-30: rgb(0 141 150 / 30%);
  --color-turquoise-alpha-10: rgb(0 141 150 / 10%);

  /* This is the same color as alpha-10 without the alpha value. */
  --color-turquoise-extra-light: #edf4f6;

  /* Fern */
  --color-fern-light: #daf2b4;
  --color-fern-bright: #a6ea3c;
  --color-fern-strong: #7cc60b;
  --color-fern-dark: #6cb100;
  --color-fern-alpha-30: rgb(108 177 0 / 30%);
  --color-fern-alpha-10: rgb(108 177 0 / 10%);
  --color-honey-light: #ffefb5;

  /* Honey */
  --color-honey-bright: #ffbe17;
  --color-honey-strong: #e7c200;
  --color-honey-dark: #db9e00;
  --color-honey-alpha-30: rgb(210 152 0 / 30%);
  --color-honey-alpha-10: rgb(210 152 0 / 10%);

  /* This is the same color as honey-alpha-10 without the alpha value. */
  --color-honey-extra-light: #faf5ed;

  /* Orange */
  --color-orange-light: #ffe0c3;
  --color-orange-bright: #ff833d;
  --color-orange-strong: #ff7a01;
  --color-orange-dark: #d64d00;
  --color-orange-alpha-30: rgb(229 99 26 / 30%);
  --color-orange-alpha-10: rgb(229 99 26 / 10%);

  /* Salmon */
  --color-salmon-bright: #f46a8a;
  --color-salmon-strong: #e2476a;
  --color-salmon-dark: #c5183f;
  --color-salmon-light: #ffdee6;
  --color-salmon-alpha-30: rgb(213 54 90 / 30%);
  --color-salmon-alpha-10: rgb(213 54 90 / 10%);

  /* This is the same color as alpha-10 without the alpha value. */
  --color-salmon-extra-light: #faeef0;

  /* Pink */
  --color-pink-light: #ffe0f8;
  --color-pink-bright: #eb60d0;
  --color-pink-strong: #d71eb3;
  --color-pink-dark: #b90195;
  --color-pink-alpha-30: rgb(195 50 167 / 30%);
  --color-pink-alpha-10: rgb(195 50 167 / 10%);

  /* Charcoal */
  --color-charcoal-5: #f1f1f1;
  --color-charcoal-10: #e1e0e1;
  --color-charcoal-30: #c2c2c2;
  --color-charcoal-40: #a4a3a4;
  --color-charcoal-60: #7b7a7c;
  --color-charcoal-80: #525253;
  --color-charcoal-90: #343335;
  --color-charcoal-95: #262526;
  --color-charcoal-100: #151515;
  --color-charcoal-alpha-7: rgb(21 21 21 / 7%);
  --color-charcoal-alpha-10: rgb(21 21 21 / 10%);
  --color-charcoal-alpha-15: rgb(21 21 21 / 15%);
  --color-charcoal-alpha-20: rgb(21 21 21 / 20%);
  --color-charcoal-alpha-30: rgb(21 21 21 / 30%);
  --color-charcoal-alpha-45: rgb(21 21 21 / 45%);
  --color-charcoal-alpha-65: rgb(21 21 21 / 65%);
  --color-charcoal-alpha-85: rgb(21 21 21 / 85%);
  --color-charcoal-alpha-100: rgb(21 21 21 / 100%);
  --color-alpha-7: rgb(0 0 0 / 7%);
  --color-alpha-15: rgb(0 0 0 / 15%);
  --color-alpha-30: rgb(0 0 0 / 30%);
  --color-alpha-45: rgb(0 0 0 / 45%);
  --color-alpha-65: rgb(0 0 0 / 65%);
  --color-alpha-85: rgb(0 0 0 / 85%);
  --color-alpha-100: rgb(0 0 0 / 100%);
  --color-white: #fff;
  --color-white-alpha-7: rgb(255 255 255 / 7%);
  --color-white-alpha-15: rgb(255 255 255 / 15%);
  --color-white-alpha-30: rgb(255 255 255 / 30%);
  --color-white-alpha-45: rgb(255 255 255 / 45%);
  --color-white-alpha-65: rgb(255 255 255 / 65%);
  --color-white-alpha-85: rgb(255 255 255 / 85%);
  --color-white-alpha-100: rgb(255 255 255 / 100%);
  --font-sans: 'inter', sans-serif;
  --shade-sm: 0px 2px 8px var(--color-charcoal-alpha-7);
  --shade-md: 0px 4px 16px var(--color-charcoal-alpha-10);
  --shade-lg: 0 4px 24px 0 var(--color-charcoal-alpha-15);
  --shade-xl: 0 4px 24px 0 var(--color-charcoal-alpha-20);
  --spacing-0-25: 2px;
  --spacing-0-5: 4px;
  --spacing-0-75: 6px;
  --spacing-1: 8px;
  --spacing-1-25: 10px;
  --spacing-1-5: 12px;
  --spacing-1-75: 14px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 32px;
  --spacing-5: 40px;
  --radius-xxs: 6px;
  --radius-xs: 8px;
  --radius-sm: 10px;
  --radius-md: 12px;
  --radius-lg: 16px;
  --radius-xl: 24px;
  --radius-xxl: 28px;
}

body {
  background: var(--background-color);
  color: var(--color-text);
  font-family: var(--font-sans);
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

img {
  pointer-events: none;
  -webkit-user-drag: none;
}
